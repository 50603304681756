<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> {{ $route.meta.title }}
      </div>

      <div class="card">

        <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
          <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
            <InputText v-model="filters.filter" placeholder="Pesquisar" @change="loadRecords" />
          </div>
          <div class="p-fluid mt-2 w-full md:mt-0 md:w-5 ml-4">
            <Calendar v-model="dateStartFormated" @update:v-model="filters.dateStart" :showIcon="true" placeholder="Data inicial" @date-select="selectDate('start')"/>
          </div>
          <div class="p-fluid mt-2 w-full md:mt-0 md:w-5 ml-4">
            <Calendar v-model="dateFinishFormated" @update:v-model="filters.dateFinish" :showIcon="true" placeholder="Data final" @date-select="selectDate('finish')"/>
          </div>
          <div class="mt-2 ml-auto md:mt-0">
            <Button label="Exportar" icon="pi pi-save" class="p-button p-button-text mr-2" @click="download" />
          </div>
        </div>

        <DataTable dataKey="id" :value="results" :row-hover="true" responsiveLayout="stack" breakpoint="640px">

          <template #empty> Nenhum registro encontrado. </template>

          <template #loading> Carregando registros. Aguarde ... </template>

          <Column field="id" header="#"></Column>

          <Column field="updatedAt" header="Data alteração">
            <template #body="{ data }">
              {{ $filters.dateTime(data.updatedAt) }}
            </template>
          </Column>

          <Column field="documento" header="Documento">
            <template #body="{ data }">
              {{ $filters.cpfCnpj(data.documento) }}
            </template>
          </Column>

          <Column field="devedor.nome" header="Nome"></Column>

          <Column field="carteira" header="Carteira"></Column>

          <Column field="cliente" header="Cliente"></Column>

          <Column field="tipo" header="Tipo"></Column>

          <Column header="Log">
            <template #body="{ data }">
              <Button icon="pi pi-check" class="p-button-success p-button-rounded p-button-text"
                @click="openLog(data)" />
            </template>
          </Column>

        </DataTable>

        <Paginator :rows="perPage" :totalRecords="totalItems" @page="onPage"
          template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
          currentPageReportTemplate="Página {currentPage} de {totalPages}">
        </Paginator>

        <Dialog header="Log da transação" v-model:visible="displayLog" :modal="true">
          <json-viewer :value="log" :expand-depth=5 copyable boxed sort>
          </json-viewer>

          <template #footer>
            <Button label="Fechar" icon="pi pi-times" @click="closeLog" class="p-button-text" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer';
import fileDownload from 'js-file-download';
import PageableList from '../../components/abstract/PageableList.vue';
import CrudService from '@/services/crud'
import { getClient } from '../../services/http';
import moment from 'moment';

export default {
  extends: PageableList,
  components: {
    JsonViewer
  },
  created() {
    const startDate = this.$route.query.startDate;
    const finishDate = this.$route.query.finishDate;

    if (startDate) {
      this.dateStartFormated = moment(startDate).format('DD/MM/YYYY');
      this.filters.dateStart = startDate;
    }

    if (finishDate) {
      this.filters.dateFinish = finishDate;
      this.dateFinishFormated = moment(finishDate).format('DD/MM/YYYY');
    }

    this.type = this.$route.meta.type;
  },
  data() {
    return {
      service: new CrudService(`/admin/relatorios/${this.$route.meta.type}`),
      displayLog: false,
      log: null,
      results: [],
      dateStartFormated: null,
      dateFinishFormated: null,
    }
  },
  methods: {
    openLog(item) {
      this.log = item.data;
      this.displayLog = true;
    },
    closeLog() {
      this.log = null;
      this.displayLog = false;
    },
    selectDate(date) {

      const query = { ...this.$route.query};

      if (date === 'start') {
        const dateStart = this.dateStartFormated;
        this.filters.dateStart = moment(dateStart, 'MM/DD/YYYY').startOf('day').format();
        this.dateStartFormated = moment(dateStart, 'MM/DD/YYYY').format('DD/MM/YYYY');
        query.startDate = this.filters.dateStart;
      }

      if (date === 'finish') {
        const dateFinish = this.dateFinishFormated;
        this.dateFinishFormated = moment(dateFinish, 'MM/DD/YYYY').format('DD/MM/YYYY');
        this.filters.dateFinish = moment(dateFinish, 'MM/DD/YYYY').endOf('day').format();
        query.finishDate = this.filters.dateFinish;
      }

      this.$router.push({ query });
      // this.loadRecords();
    },
    async download() {
      const { data } = await getClient().get(`/admin/relatorios/${this.type}/csv`, {
        params: {
          filter: this.filters.filter,
          dateStart: this.filters.dateStart,
          dateFinish: this.filters.dateFinish
        }
      })
      fileDownload(data, `${this.type}.csv`)
    },
    async loadRecords() {
      const { data } = await this.service.findAll({
        page: this.page,
        limit: this.perPage,
        ...this.filters
      });
      this.results = [];

      // only for search event
      if (this.$route.meta.type === 'search') {

        for (const item of data.items) {
          if (item.type !== 'search') continue;
          if (item.data?.debitos) {
            for (const debit of item.data.debitos) {
              this.results.push({
                id: item.id,
                documento: item.documento,
                updatedAt: item.updatedAt,
                devedor: {
                  nome: item.devedor ? item.devedor.nome : item.lastSearch.nome
                },
                carteira: debit.carteira,
                cliente: debit.tipoIntegracao,
                tipo: 'débito',
              });
            }
          }

          if (item.data?.acordos) {
            for (const debit of item.data.acordos) {
              this.results.push({
                id: item.id,
                documento: item.documento,
                updatedAt: item.updatedAt,
                devedor: {
                  nome: item.devedor ? item.devedor.nome : '-'
                },
                carteira: debit.carteira,
                cliente: debit.tipoIntegracao,
                tipo: 'acordo',
              });
            }
          }
        }
      } else if (this.$route.meta.type === 'searchAll') {
        for (const item of data.items) {

          this.results.push({
            id: item.id,
            documento: item.documento,
            updatedAt: item.updatedAt,
            devedor: {
              nome: item.devedor ? item.devedor.nome : item.lastSearch.nome
            },
            carteira: item.lastSearch ? item.lastSearch.carteira : '-',
            cliente: item.lastSearch ? item.lastSearch.cliente : '-',
            tipo: item.data.debitos.length > 0 ? 'consulta com débito' : 'consulta sem débito',
          });

          if (item.data?.acordos) {
            for (const debit of item.data.acordos) {
              this.results.push({
                id: item.id,
                documento: item.documento,
                updatedAt: item.updatedAt,
                devedor: {
                  nome: item.devedor ? item.devedor.nome : '-'
                },
                carteira: debit.carteira,
                cliente: debit.tipoIntegracao,
                tipo: 'acordo',
              });
            }
          }
        }
      } else if (this.$route.meta.type === 'login') {

        for (const item of data.items) {
          if (item.type !== 'login') continue;
          this.results.push({
            id: item.id,
            documento: item.documento,
            updatedAt: item.updatedAt,
            devedor: {
              nome: item.devedor ? item.devedor.nome : (item.data.devedor ? item.data.devedor.nome : 'Sem nome')
            },
            carteira: item.lastSearch ? item.lastSearch.carteira : '-',
            cliente: item.lastSearch ? item.lastSearch.cliente : '-',
            tipo: 'login na plataforma',
          });
        }

      } else if (this.$route.meta.type === 'confirm') {

        for (const item of data.items) {
          if (item.type !== 'confirm') continue;
          this.results.push({
            id: item.id,
            documento: item.documento,
            updatedAt: item.updatedAt,
            devedor: {
              nome: item.devedor ? item.devedor.nome : (item.data.devedor ? item.data.devedor.nome : 'Sem nome')
            },
            carteira: item.lastSearch ? item.lastSearch.carteira : '-',
            cliente: item.lastSearch ? item.lastSearch.cliente : '-',
            tipo: 'confirmação de cadastro',
          });
        }

      } else if (this.$route.meta.type === 'proposal') {

          for (const item of data.items) {
            if (item.type !== 'proposal') continue;
            this.results.push({
              id: item.id,
              documento: item.documento,
              updatedAt: item.updatedAt,
              devedor: {
                nome: item.devedor ? item.devedor.nome : (item.data.devedor ? item.data.devedor.nome : 'Sem nome')
              },
              carteira: item.lastSearch ? item.lastSearch.carteira : '-',
              cliente: item.data.detalhes.tipoIntegracao ? item.data.detalhes.tipoIntegracao : (item.lastSearch ? item.lastSearch.cliente : '-'),
              tipo: 'proposta de acordo',
            });
          }

      } else if (this.$route.meta.type === 'agreement') {

          for (const item of data.items) {
            if (item.type !== 'agreement') continue;
            this.results.push({
              id: item.id,
              documento: item.documento,
              updatedAt: item.updatedAt,
              devedor: {
                nome: item.devedor ? item.devedor.nome : (item.data.devedor ? item.data.devedor.nome : 'Sem nome')
              },
              carteira: item.lastSearch ? item.lastSearch.carteira : '-',
              cliente: item.data.detalhes.tipoIntegracao ? item.data.detalhes.tipoIntegracao : (item.lastSearch ? item.lastSearch.cliente : '-'),
              tipo: 'acordo',
            });
          }

      } else {
        this.results = data.items;
      }
      this.totalItems = data.meta?.totalItems;
      this.totalPages = data.meta?.totalPages;
    },
    onPage(event) {
      this.page = event.page + 1;
      this.loadRecords();
    }
  }
}
</script>

<style scoped lang="scss">

</style>
